import capitalize from 'lodash/capitalize';
import { useEffect } from 'react';
import useSWRImmutable from 'swr/immutable';
import { proxy, useSnapshot } from 'valtio';

import { type CodeCanvasBlock } from '@lp-lib/game';

import { useLiveAsyncCall } from '../../../../../hooks/useAsyncCall';
import { assertExhaustive } from '../../../../../utils/common';
import { CodeCanvasUtils } from '../../../../GameV2/blocks/CodeCanvas/utils';
import { Loading } from '../../../../Loading';
import { DialogueEditor } from '../../../../VoiceOver/Dialogue/DialogueEditor';
import {
  findDefaultPersonality,
  usePersonalities,
} from '../../../../VoiceOver/usePersonalities';
import { useTrainingSlideEditor } from '../../hooks';
import { type TrainingSlideEditorProps } from '../../types';
import { useTriggerCodeGenerator } from './CodeGenerator';
import { CodeCanvasEditorUtils } from './utils';

const tabs = ['preview', 'code'] as const;

type Tab = (typeof tabs)[number];

type State = {
  activeTab: Tab;
};

const state = proxy<State>({
  activeTab: 'preview',
});

export function CodeCanvasBlockEditorTabs() {
  const { activeTab } = useSnapshot(state);

  useEffect(() => {
    // Reset state on unmount
    return () => {
      state.activeTab = 'preview';
    };
  }, []);

  return (
    <div className='p-2 flex items-center gap-2.5 text-white'>
      {tabs.map((tab) => (
        <button
          type='button'
          key={tab}
          className={`
            w-25 h-8 bg-secondary rounded-lg
            border ${activeTab === tab ? 'border-white' : 'border-transparent'}
          `}
          onClick={() => {
            state.activeTab = tab;
          }}
        >
          {capitalize(tab)}
        </button>
      ))}
    </div>
  );
}

// --- Code Tab ---
function CodeTab(props: TrainingSlideEditorProps<CodeCanvasBlock>) {
  const { block } = props;
  const { onChange, onBlur } = useTrainingSlideEditor(props);

  const {
    data: code,
    isLoading,
    mutate,
  } = useSWRImmutable(
    block.fields.codeTimestamp
      ? `${block.id}-${block.fields.codeTimestamp}-code`
      : null,
    async () => {
      if (!block.fields.codeTimestamp) {
        return '';
      }

      const fetchedCode = await CodeCanvasEditorUtils.FetchCode(
        block.id,
        block.fields.codeTimestamp
      );
      return fetchedCode;
    }
  );

  const {
    call: handleBlur,
    state: {
      state: { isRunning: isUploading },
    },
  } = useLiveAsyncCall(async (next: string) => {
    if (next === code) return;

    await CodeCanvasUtils.UploadCode(block.id, next);

    const newTimestamp = Date.now();
    const timestampString = newTimestamp.toString();
    mutate(next, {
      revalidate: false,
    });
    onChange('codeTimestamp', timestampString);
    onBlur('codeTimestamp', timestampString);
  });

  if (isLoading) {
    return (
      <div className='absolute inset-0 z-50 flex items-center justify-center bg-lp-black-004'>
        <Loading text='Loading Code...' />
      </div>
    );
  }

  return (
    <div className='absolute inset-0 z-50 flex flex-col'>
      <textarea
        autoFocus
        className='w-full h-full field p-2 m-0 flex-grow disabled:opacity-50'
        key={block.fields.codeTimestamp}
        defaultValue={code}
        onBlur={(e) => handleBlur(e.target.value)}
        disabled={isUploading}
      />
    </div>
  );
}

function PreviewTab(props: TrainingSlideEditorProps<CodeCanvasBlock>) {
  const { block } = props;

  const src = CodeCanvasUtils.GetCodeS3Url(
    block.id,
    block.fields.codeTimestamp
  );
  if (!src) {
    return (
      <div className='w-full h-full flex items-center justify-center text-icon-gray'>
        No code provided. Edit in the 'Code' tab or generate content.
      </div>
    );
  }

  return (
    <div className='absolute inset-0'>
      <iframe src={src} className='w-full h-full' title='Code Preview' />;
    </div>
  );
}

export function CodeCanvasBlockEditor(
  props: TrainingSlideEditorProps<CodeCanvasBlock>
) {
  const { activeTab } = useSnapshot(state);

  switch (activeTab) {
    case 'preview':
      return <PreviewTab {...props} />;
    case 'code':
      return <CodeTab {...props} />;
    default:
      assertExhaustive(activeTab);
  }
}

export function CodeCanvasBlockEditorUnder(
  props: TrainingSlideEditorProps<CodeCanvasBlock>
) {
  const { block } = props;

  const { onChange, onBlur } = useTrainingSlideEditor(props);

  return (
    <div className='flex flex-col gap-5 text-white'>
      <DialogueEditor
        key={block.id}
        offlineRendering={false}
        value={block.fields.dialogue}
        enabledMarkTypes={['trigger', 'tutor-question']}
        importEnabled={true}
        onChange={(value) => {
          onChange('dialogue', value);
          onBlur('dialogue', value);
        }}
      />
    </div>
  );
}

export function CodeCanvasBlockSidebarEditor(
  props: TrainingSlideEditorProps<CodeCanvasBlock>
) {
  const { block } = props;
  const triggerModal = useTriggerCodeGenerator();
  const { onChange, onBlur } = useTrainingSlideEditor(props);
  const { data: personalities } = usePersonalities();
  const defaultPersonalityId = findDefaultPersonality(personalities);

  const {
    call: generate,
    state: {
      state: { isRunning },
    },
  } = useLiveAsyncCall(async () => {
    triggerModal({
      onGenerate: async (description) => {
        // Generate Dialogue and HTML
        const { dialogue, html } = await CodeCanvasEditorUtils.GenerateCode(
          description,
          personalities ?? [],
          defaultPersonalityId?.id ?? ''
        );

        await CodeCanvasUtils.UploadCode(block.id, html);

        // Update timestamp in state to trigger preview refresh
        const newTimestamp = Date.now();
        const timestampString = newTimestamp.toString();

        // Update block fields
        onChange('dialogue', dialogue);
        onBlur('dialogue', dialogue);
        // Store the timestamp string in the 'code' field
        onChange('codeTimestamp', timestampString);
        onBlur('codeTimestamp', timestampString);
      },
    });
  });

  return (
    <div className='flex flex-col gap-5 text-white'>
      <button
        type='button'
        className='btn-primary w-full py-2'
        disabled={isRunning}
        onClick={() => generate()}
      >
        {isRunning ? 'Generating...' : 'Generate'}
      </button>
    </div>
  );
}
