import { useState } from 'react';

import { useLiveCallback } from '../../../../../hooks/useLiveCallback';
import { useAwaitFullScreenConfirmCancelModal } from '../../../../ConfirmCancelModalContext';

export function CodeGeneratorModal(props: {
  onCancel: () => void;
  onGenerate: (description: string) => void;
}) {
  const { onCancel, onGenerate } = props;

  const [description, setDescription] = useState('');

  const handleGenerate = useLiveCallback(() => {
    onGenerate(description);
  });

  return (
    <div className='border border-secondary bg-black rounded-xl px-5 py-3 w-180 min-h-45'>
      <div className='w-full h-full flex flex-col text-white'>
        {/* <div>
          <div className='flex-none w-full py-2'>
            <div className='font-bold text-lg'>Tutor Question</div>
            <div className='pt-1 text-sm text-icon-gray'>
              The tutor will proactively ask the learner this question
            </div>
          </div>

          <div className='w-full flex-1 min-h-0 pt-2 pb-4'>
            <input
              ref={questionRef}
              className={`w-full h-12.5 field m-0`}
              placeholder={'Enter tutor question...'}
              defaultValue={props.value.question}
              autoFocus
            />
          </div>
        </div> */}
        <div>
          <div className='flex-none w-full py-2'>
            <div className='font-bold text-lg'>Describe your slide</div>
          </div>

          <div className='w-full flex-1 min-h-0 pt-2 pb-4'>
            <textarea
              className={`w-full h-20 field m-0 py-3`}
              placeholder={'A slide to...'}
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </div>
        </div>

        <div className='mt-auto w-full flex items-center justify-end gap-4'>
          <button
            type='button'
            className='btn-secondary w-40 py-2'
            onClick={onCancel}
          >
            Cancel
          </button>
          <button
            type='button'
            className='btn-primary w-40 py-2'
            onClick={handleGenerate}
          >
            Generate
          </button>
        </div>
      </div>
    </div>
  );
}

export function useTriggerCodeGenerator() {
  const triggerModal = useAwaitFullScreenConfirmCancelModal();
  return useLiveCallback(
    async (props: { onGenerate: (description: string) => void }) => {
      triggerModal({
        kind: 'custom',
        element: (p) => (
          <CodeGeneratorModal
            onCancel={() => {
              p.internalOnCancel();
            }}
            onGenerate={(description) => {
              props.onGenerate(description);
              p.internalOnConfirm();
            }}
          />
        ),
      });
    }
  );
}
